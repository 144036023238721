import { useState, useEffect } from "react";

import './Indicators.css';

import ReactApexChart from "react-apexcharts";

import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";

import { SvgReturn } from "components/SvgFile";

export default function Indicators(props){

    const [ accessDash, setAccessDash ] = useState(InitialData('access_dash'));    
    const [ courses, setCourses ] = useState(InitialData('courses'));    
    const [ students, setStudents ] = useState(InitialData('students'));    
    const [ team, setTeam ] = useState(InitialData('team'));    
    const [ entry, setEntry ] = useState(InitialData('entry'));    
    const [ exit, setExit ] = useState(InitialData('exit'));

    const [ listData, setListData ] = useState([
        {
            "open": "",
            "title": "Acesso a dashboard",
            "qtd": accessDash.length
        },
        {
            "open": "",
            "title": "Cursos",
            "qtd": courses.length
        },
        {
            "open": "",
            "title": "Alunos",
            "qtd": students.length
        },
        {
            "open": "",
            "title": "Turmas",
            "qtd": team.length
        },
        {
            "open": "",
            "title": "Faturamento - Entrada",
            "qtd": entry.length
        },
        {
            "open": "",
            "title": "Faturamento - Saída",
            "qtd": exit.length
        },
    ]);
   
    /* show data year */ 
    const date = new Date();
    const [ showMonth, setShowMonth ] = useState(date.getMonth() + 1);
    const [ showYear, setShowYear ] = useState(date.getFullYear());
    const [ currentYear, setCurrentYear ] = useState(showYear);
    const [ series, setSeries ] = useState(ShowCashGrafic(currentYear));
    const [ options, setOptions ] = useState({
        chart: {
            height: 350,
            type: 'bar',
            zoom: {
                enabled: false
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight'
        },
        markers: {
            size: 6,
            strokeWidth: 1,
            radius: 2,
            shape: "circle"
        },
        title: {
            text: '',
            align: ''
        },
        // colors:['#7c9f06', '#ff001c'],
        grid: {
            row: {
                colors: ['#f3f3f3', 'transparent'],
                opacity: 0.5
            },
        },
        xaxis: {
            categories: ShowCategoriesGrafic(),
        }
    });
    function ShowCashGrafic(year){
        
        let data_entry = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        let data_exit  = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        const newData = [];
        GetDataPage('courses').map((elem, index)=>{
            elem.questions.map((elem_1, index_1)=>{
                // newData.push({ "id_course" });
                console.log(elem_1);                
            })
        });
        return newData;
    }
    function ShowCategoriesGrafic(){
        const newData = [];
        GetDataPage('courses').map((elem, index)=>{
            if(elem.questions.length > 0){
                console.log(elem.title);
                
                newData.push(elem.title);
            }
        });
        return newData;
    }
    /* end */

    function InitialData(type){
        const newData = GetDataPage(type).filter(item => item.status == 1);
        if(newData){
            return newData;
        }
        return [];
    }
    
    useEffect(()=>{
        RegisterDataPage('access_dash', setAccessDash);
        RegisterDataPage('courses', setCourses);
        RegisterDataPage('students', setStudents);
        RegisterDataPage('team', setTeam);
        RegisterDataPage('entry', setEntry);
        RegisterDataPage('exit', setExit);        

        return ()=>{
            UnRegisterDataPage('access_dash', setAccessDash);
            UnRegisterDataPage('courses', setCourses);
            UnRegisterDataPage('students', setStudents);
            UnRegisterDataPage('team', setTeam);
            UnRegisterDataPage('entry', setEntry);
            UnRegisterDataPage('exit', setExit);
        };
    }, []);

    return(
        <div className="Indicators">
            <div className="new_block">
                <div className="title_page">Indicadores</div>
            </div>
            
            <div className="list_current_data">
                {
                    listData.map((key, index)=>{
                        return(
                            <div className="show_qtd" key={ index } onClick={ ()=>{ }}>
                                <div className="number">{ key.qtd }</div>
                                <div className="title">{ key.title }</div>
                            </div>
                        )
                    })
                }
            </div>

            <div className="new_block new_block_grafic">
                <div className="" onClick={ ()=>{ setCurrentYear((currentYear - 1)); setSeries(ShowCashGrafic((currentYear - 1))); } }>
                    <SvgReturn color="#324d6b" className="icons" />
                </div>
                <div className="title_page">
                    Questionário dos cursos respondido - { currentYear }
                </div>
                <div className="" onClick={ ()=>{ setCurrentYear((currentYear + 1)); setSeries(ShowCashGrafic((currentYear + 1)));} }>
                    <SvgReturn color="#324d6b" className="icons icon_next" />
                </div>
            </div>

            <div className="list_data_page">
                <div className="div_grafic">
                    <ReactApexChart options={ options } series={ series } type="line" height={ 350 } />
                </div>
            </div>

        </div>
    )
}