import React, { useState, useEffect } from "react";

import './ShowFile.css';

import { SvgClose } from "components/SvgFile";

import { RegisterModalData, RegisterModalObserver, SetModalState } from "interface/PopUp";

export default function PopUp_ShowFile(){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    useEffect(()=>{
        RegisterModalData('ShowFile', setModaldata);
        RegisterModalObserver('ShowFile', setShowPopUp);
    }, []);

    function ClosePopUp(){
        SetModalState('ShowFile', false);
    }

    function OpenFile(){
        switch (modalData.type) {
            case 'showImg':
                return <img alt="img" src={ "./assets/" + modalData.file } className="show_img" />;

            case 'showImg_local':
                return <img alt="img" src={ modalData.file } className="show_img" />;

            case 'showPdf':
                return <iframe url={ "./assets/" + modalData.file } width="98%" height="400px" id="" className="div_video" display="block" position="relative" />;

            case 'showPdf_local':
                return <iframe url={ modalData.file } width="98%" height="400px" id="" className="div_video" display="block" position="relative" />;


            case 'VimeoCode':
                return <iframe className="div_video" src={ "https://player.vimeo.com/video/" + modalData.file } frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen="" />;

            case 'YoutubeCode':
                return <iframe className="div_video" src={ "https://www.youtube.com/embed/" + modalData.file } frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen="" />;

            case 'Vimeo':
                let linkVimeo = modalData.file.split('/');
                return (
                    <div className="aspect_video">
                        <iframe className="div_video" src={ "https://player.vimeo.com/video/" + linkVimeo[3] } frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen="" />
                    </div>
                );
            case 'Youtube':
                let linkYoutube = modalData.file.split('=');
                return (
                    <div className="aspect_video">
                        <iframe className="div_video" src={ "https://www.youtube.com/embed/" + linkYoutube[1] } frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen="" />
                    </div>
                );

            case 'others':
                let ext = modalData.file.split('.');
                switch (ext[1]) {
                    case "png": case "jpg": case "jpge": case "gif":
                        return <img alt="img" src={ "./assets/" + modalData.file } className="show_img" />;
                    
                    case "pdf":
                        return <iframe url={ "./assets/" + modalData.file } width="98%" height="400px" id="" className="" display="block" position="relative" />;
                    
                    default:
                        return(
                            <div className="">Não suportado!</div>
                        );
                }
        }
    }

    return (
        (showPopUp ?
            <>
                <div className="PopUp">
                    <div className="all show_file">
                        <div className="div_data">
                            <div className="title title_file">
                                <div className="popup_close" onClick={ ()=>{ ClosePopUp(); } }>
                                    <SvgClose color="#F00000" className="icons" />
                                </div>
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="content">
                                {
                                    OpenFile()
                                }
                            </div>
                        </div>
                    </div>
                    <div className="close_all" onClick={ ()=>{ ClosePopUp(); } } />
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
